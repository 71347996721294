import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { displayDuration, penniesToDollars } from '../../util/helper';

const moment = require('moment-timezone');

export default class Vehicles extends Component {
  render() {
    const { vehicleRides = [], onClickInvoice, onClickRideMap } = this.props;
    return (
      <Fragment>
        <h2>Ride History</h2>
        {vehicleRides.length === 0 ? (
          <p>No rides found</p>
        ) : (
          <ReactTable
            ref={(r) => {
              this.selectTable = r;
            }}
            filterable
            onFilteredChange={this.onFilteredChange}
            className="-striped -highlight users_table user_table_add_on "
            data={vehicleRides}
            showPaginationBottom={vehicleRides.length > 50}
            pageSize={50}
            getTrProps={(state, rowInfo) => ({
              onClick: () => onClickInvoice(rowInfo.original),
            })}
            columns={[
              {
                Header: 'Zone',
                accessor: 'start_zone_name',
                maxWidth: 75,
                Cell: (props) => (
                  <div
                    onClick={(event) => {
                      onClickRideMap(props.original);
                      event.stopPropagation();
                    }}>
                    <span className="underline scooterLink">{props.value}</span>
                  </div>
                ),
              },
              {
                Header: 'User Id',
                accessor: 'user_id',
                width: 190,
                Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
              },
              {
                Header: 'Start (Pacific)',
                accessor: 'created_date',
                Cell: (props) => (
                  <Fragment>
                    {props.value &&
                      moment(props.value).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
                  </Fragment>
                ),
                maxWidth: 175,
                sortMethod: (a, b) => {
                  if (a === b) {
                    return 0;
                  }
                  return moment(a).isBefore(b) ? 1 : -1;
                },
              },
              {
                Header: 'Duration',
                accessor: 'duration',
                Cell: (props) => <Fragment>{displayDuration(props.value)}</Fragment>,
                maxWidth: 100,
                sortMethod: (a, b) => {
                  if (a === b) {
                    return 0;
                  }
                  return a < b ? 1 : -1;
                },
              },
              {
                Header: 'Charged',
                accessor: 'charged_amount',
                maxWidth: 70,
                className: 'currency',
                Cell: (props) => <Fragment>{penniesToDollars(props.value)}</Fragment>,
                sortMethod: (a, b) => {
                  if (a === b) {
                    return 0;
                  }
                  return a < b ? 1 : -1;
                },
              },
              {
                Header: 'Ride End Reason',
                accessor: 'ride_end_reason',
                maxWidth: 150,
              },
              {
                Header: 'Payment Status',
                accessor: 'payment_status',
                maxWidth: 100,
                Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
              },
            ]}
            defaultSorted={[
              {
                id: 'created_date',
                desc: false,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}

Vehicles.propTypes = {
  vehicleRides: PropTypes.array,
  onClickInvoice: PropTypes.func.isRequired,
  onClickRideMap: PropTypes.func.isRequired,
};
