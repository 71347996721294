// TODO: Refactor to functional component

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { patchVehicle } from '../../../api';
import { getBatteryTypes } from '../../../state/battery_types';
import { getCommTypes } from '../../../state/comm_types';
import { getRideById, getRideLocations, getRefundForRide } from '../../../state/rides';
import { getVehicleTypes } from '../../../state/vehicle_types';
import { getVehicleStatuses } from '../../../state/vehicle_statuses';
import { getFeatures } from '../../../state/zones';
import { getVehicleRides, clearVehicleRides } from '../../../state/vehicle_rides';
import Vehicles from '../../../Components/Vehicles';
import { clearVehicle, getVehicleByName, writeoff } from '../../../state/vehicle';
import AuthenticatedPage from '../../../Components/AuthenticatedPage';
import Loader from '../../../Components/Loader';
import Popup from 'reactjs-popup';
import Map from '../../../Components/ZoneMap';
import { MAP } from 'react-google-maps/lib/constants';
import { INVOICE } from '../../../util/constants';
import { getTimeInterval, penniesToDollars } from '../../../util/helper';
import RideDetails from '../../../Components/RideDetails';
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import ModalTitle from '../../../Components/ModalTitle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropdownList } from 'react-widgets';

import closeButtonImage from '../../../images/close.png';

import './index.scss';

class EditScooter extends Component {
  recoveryReasons = ['Found During Recovery', 'Recovered in the field', 'Other'];
  writeoffReasons = [
    'Status picked_up/collected but never recovered',
    'Lost in warehouse',
    'Long recovery lag time',
    'Thrown into water',
    'Taken far from zone',
    'Taken to private residence',
    'Unable to access',
    'No trace of scooter or GPS only',
    'Not found during inventory',
    'Damaged Scrapped',
  ];

  constructor(props) {
    super(props);
    let vehicle;
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.selectedScooter
    ) {
      vehicle = this.props.location.state.selectedScooter.vehicle;
      this.state = {
        vehicle,
        iccid: vehicle.iccid,
        name: vehicle.name,
      };
    }
    this.state = {
      name: vehicle ? vehicle.name : '',
      feedback: '',
      feedbackColor: 'red',
      disabled: false,
      loaded: true,
      showVehicleRidesTable: false,
      open: false,
      hasLock: null,
      isFromRedirect: false,
      visible: false,
      selectedZone: null,
      isWrittenoff: false,
      selectedRecoveryReason: 'Found During Recovery',
      selectedWriteoffReason: 'Status picked_up/collected but never recovered',
      vehicle_zone: '',
      vehicleStatus: undefined,
      hasLoadedSentVehicle: false,
      hasDeletedIccid: false,
      active_zones: this.props.zones.filter((zone) => zone.active),
    };

    this.props.actions.getVehicleStatuses().catch((error) => console.log(error));
    this.props.actions.getBatteryTypes().catch((error) => console.log(error));
    this.props.actions.getCommTypes().catch((error) => console.log(error));
    this.props.actions.getVehicleTypes().catch((error) => console.log(error));
  }

  static getDerivedStateFromProps(props, state) {
    let newState;
    const { vehicle } = props;
    let iccid;
    if (state.hasDeletedIccid) {
      iccid = '';
    } else {
      iccid = state.iccid || (vehicle && vehicle.iccid);
    }
    const vehicleStatus = state.vehicle
      ? state.vehicleStatus
      : props.vehicle && props.vehicle.status;
    if (
      props.location &&
      props.location.data &&
      props.location.data.selectedScooter.vehicle &&
      !state.hasLoadedSentVehicle
    ) {
      const passedVehicle = props.location.data.selectedScooter.vehicle;
      if (passedVehicle.iccid) {
        newState = {
          vehicle: passedVehicle,
          iccid: passedVehicle.iccid,
          name: passedVehicle.name,
          vehicleType: passedVehicle.vehicle_type,
          commType: passedVehicle.comm_type.id,
          batteryType: passedVehicle.batteryType,
          vehicleStatus: passedVehicle.status,
          hasLoadedSentVehicle: true,
          hasLock: passedVehicle.has_lock,
        };
      } else {
        props.actions.getVehicleByName(passedVehicle.name);
        newState = {
          vehicle: vehicle,
          iccid: vehicle.iccid,
          name: vehicle.name,
          vehicleType: vehicle.vehicle_type,
          commType: vehicle.comm_type.id,
          batteryType: vehicle.batteryType,
          vehicleStatus: vehicle.status,
          hasLoadedSentVehicle: true,
          hasLock: vehicle.has_lock,
        };
      }
    } else {
      if (state.iccid === '') {
        newState = {
          ...state,
          vehicle,
          name: vehicle ? vehicle.name : state.name,
          iccid: iccid,
          vehicleType: state.vehicleType || (vehicle && vehicle.vehicle_type),
          commType: state.commType || (vehicle && vehicle.comm_type.id),
          batteryType: state.batteryType || (vehicle && vehicle.battery_type),
          vehicleStatus: state.vehicleStatus,
          hasLock: vehicle && vehicle.has_lock,
        };
      } else {
        newState = {
          ...state,
          vehicle,
          name: vehicle ? vehicle.name : state.name,
          iccid: iccid,
          vehicleType: state.vehicleType || (vehicle && vehicle.vehicle_type),
          commType: state.commType || (vehicle && vehicle.comm_type.id),
          batteryType: state.batteryType || (vehicle && vehicle.battery_type),
          vehicleStatus: vehicleStatus || (vehicle && vehicle.status),
          hasLock: state.hasLock !== null ? state.hasLock : vehicle && vehicle.has_lock,
        };
      }
    }
    return newState;
  }

  componentDidMount() {
    const scooterData = this.props.location;
    if (scooterData.data && scooterData.data.selectedScooter.vehicle.name) {
      this.onSubmitVehicle(scooterData.data.selectedScooter.vehicle.name);
    }
    if (this.props.vehicle && this.props.vehicle.status === 'written_off') {
      this.setState({ isWrittenoff: true });
    }
  }

  showModal = () => {
    this.setZoneName(this.props.vehicle.zone_id);
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.state.isWrittenoff ? this.recoverScooter() : this.writeoffScooter();
    this.setState({
      visible: false,
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleZoneChange = (selectedZone) => {
    this.setState({ selectedZone });
  };

  handleRecoveryReasonChange = (selectedRecoveryReason) => {
    this.setState({ selectedRecoveryReason });
  };

  handleWriteoffReasonChange = (selectedWriteoffReason) => {
    this.setState({ selectedWriteoffReason });
  };

  setZoneName = (zone_id) => {
    const vehicle_zone = this.props.zones.filter((zone) => zone.id === zone_id);
    this.setState({
      vehicle_zone: vehicle_zone,
    });
  };

  onChange = (event) => {
    let { name, value } = event.target;
    if (name === 'iccid') {
      this.setState({
        hasDeletedIccid: false,
      });
      value = value.substr(0, 20);
    }
    this.setState({
      [name]: value,
    });
  };

  openInvoicePopup = (ride) => {
    this.setState({ loaded: false });
    const payment_status = ride.payment_status;
    const selectedVehicleModel = ride.vehicle.model_name;
    const perMinuteCharge = ride.price.per_minute_price;
    const baseCharge = ride.price.base_price;
    const ride_id = ride.id;
    let refundedAmount = '';
    //if ride is partially refunded, get the amount refunded to display on invoice popup
    if (payment_status === 'partially_refunded') {
      this.props.actions.getRefundForRide(ride_id).then(() => {
        refundedAmount = penniesToDollars(((this.props.rides || {}).refund || {}).refund_amount);
        this.setState({
          popupType: INVOICE,
          selectedVehicleModel: selectedVehicleModel,
          perMinuteCharge: perMinuteCharge,
          baseCharge: baseCharge,
          selectedRide: ride,
          previousRefundedAmount: refundedAmount,
          loaded: true,
          open: true,
        });
      });
    } else {
      this.setState({
        popupType: INVOICE,
        selectedVehicleModel: selectedVehicleModel,
        perMinuteCharge: perMinuteCharge,
        baseCharge: baseCharge,
        selectedRide: ride,
        previousRefundedAmount: refundedAmount,
        loaded: true,
        open: true,
      });
    }
  };

  onSubmitVehicle = async (submitVal) => {
    let name;
    let isFromRedirect = false;
    this.setState({ feedback: '', disabled: false, loaded: false });
    if (typeof submitVal === 'object' && submitVal !== null) {
      submitVal.preventDefault();
      name = this.state.name;
    } else {
      name = submitVal;
      isFromRedirect = true;
      this.setState({ isFromRedirect: false });
    }
    if (this.state.vehicle === null || isFromRedirect) {
      await this.props.actions
        .getVehicleByName(name)
        .then((vehicle) => {
          this.setState({
            vehicle,
            disabled: false,
            isWrittenoff: this.props.vehicle.status === 'written_off',
          });
          this.props.actions.getVehicleRides(this.props.vehicle.hash).then(() => {
            this.setState({
              loaded: true,
              showVehicleRidesTable: true,
            });
          });
        })
        .catch((error) => this.setState({ feedback: error.message }));
    } else if (!isFromRedirect) {
      const { vehicle, hasLock, iccid, vehicleType, commType, batteryType, vehicleStatus } =
        this.state;

      const params = {};
      const hasChangedICCID = iccid !== vehicle.iccid;
      const hasChangedVehicleType = Number(vehicleType) !== vehicle.vehicle_type;
      const hasChangedCommType = Number(commType) !== vehicle.comm_type.id;
      const hasChangedBatteryType = Number(batteryType) !== vehicle.battery_type;

      const hasChangedStatus = vehicleStatus !== vehicle.status;
      if (hasChangedICCID && !this.state.hasDeletedIccid) {
        params.iccid = iccid;
      }
      if (hasChangedVehicleType) {
        params.vehicle_type = vehicleType;
      }
      if (hasChangedCommType) {
        params.comm_type = commType;
      }
      if (hasChangedBatteryType) {
        params.battery_type = batteryType;
      }
      if (hasChangedStatus) {
        params.status = vehicleStatus;
      }
      if (`${this.state.hasLock}` !== `${vehicle.has_lock}`) {
        params.has_lock = hasLock;
      }
      if (Object.keys(params).length === 0) {
        return;
      }
      patchVehicle(vehicle.hash, params)
        .then(() => {
          this.props.actions.getVehicleByName(vehicle.name);
          this.setState({
            feedback: 'Scooter updated successfully!',
            feedbackColor: 'green',
            loaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            feedback: error.message || 'An unknown error occurred',
            feedbackColor: 'red',
            disabled: false,
            loaded: true,
          });
        });
    }
  };

  writeoffScooter = async () => {
    this.setState({
      loaded: false,
    });
    const vehicle = this.props.vehicle;
    const params = {
      event_type: 'write-off',
      user_id: this.props.admin.user_id,
      status: 'written_off',
      reason: this.state.selectedWriteoffReason,
    };
    await this.props.actions.writeoff(vehicle.hash, params);
    await this.props.actions.getVehicleByName(this.state.name);
    this.setState({
      isWrittenoff: true,
      feedback: '',
      vehicleStatus: 'written_off',
      loaded: true,
    });
  };

  recoverScooter = async () => {
    this.setState({
      loaded: false,
    });
    const vehicle = this.props.vehicle;
    const params = {
      event_type: 'recovery',
      user_id: this.props.admin.user_id,
      status: null,
      reason: this.state.selectedRecoveryReason,
    };
    await this.props.actions.writeoff(vehicle.hash, params);
    await this.props.actions.getVehicleByName(this.state.name);
    this.setState({
      isWrittenoff: false,
      feedback: '',
      vehicleStatus: 'picked_up',
      loaded: true,
    });
  };

  openMapPopup = async (ride) => {
    const { start_time: start, end_location, end_time, start_zone_id: zone_id, id: ride_id } = ride;
    this.setState({
      popupType: MAP,
      loaded: false,
      mapPlaces: [],
    });
    let map_zone = 0;
    let zone_boundary_points = [];
    let vehicleLocationCount = 0;
    let formattedPlaces = [];
    this.props.actions
      .getRideLocations(ride_id)
      .then(() => {
        let timeLbl = '';
        let lat = '';
        let lng = '';
        let zIndex = 0;
        let backgroundColor = '';
        let locations = Object.entries(this.props.rides.locations);
        vehicleLocationCount = locations.length - 1;
        formattedPlaces = locations.map((place, i) => {
          if (i === 0) {
            //start location, properties saved and pushed on to end of map places array after array of places is built so it shows on top
            this.setState({ mapCenter: { lat: place[1].latitude, lng: place[1].longitude } });
            timeLbl = 'Start - O min';
            lat = place[1].latitude;
            lng = place[1].longitude;
            backgroundColor = 'Green';
            // set zIndex of start marker to highest so it displays
            zIndex = vehicleLocationCount + 2;
            map_zone = zone_id;
            if (this.props.zones.length > 0) {
              this.props.zones.forEach((zone) => {
                if (zone.id === map_zone) {
                  zone.geofence.forEach((points) => {
                    const coords = {
                      lat: points[0],
                      lng: points[1],
                    };
                    zone_boundary_points.push(coords);
                  });
                }
              });
            }
            this.setState({
              mapZoneBoundary: zone_boundary_points,
            });
          } else if (i > 0 && i <= vehicleLocationCount - 1) {
            // between start and end location
            timeLbl = getTimeInterval(start, place[1].created_date);
            timeLbl = timeLbl + ' min';
            lat = place[1].latitude;
            lng = place[1].longitude;
            backgroundColor = 'Cornsilk';
            zIndex = i;
          } else if (i === vehicleLocationCount) {
            // last recorded ride location
            // use the end_location from the rides table for the last location since
            // this may not have recorded in the vehicle_location_log
            // Todo: consider logging end_location in vehicle_location_logs, in which case return
            // will be place.latitude & place.longitude in return in the return and these can be removed from conditional
            timeLbl = getTimeInterval(start, end_time);
            timeLbl = 'End - ' + timeLbl + ' min';
            lat = end_location.latitude;
            lng = end_location.longitude;
            backgroundColor = 'Red';
            zIndex = vehicleLocationCount + 1;
          }
          return {
            id: place[1].id,
            lat: lat,
            lng: lng,
            time: timeLbl,
            backgroundColor: backgroundColor,
            zindex: zIndex,
          };
        });
      })
      .then(() => this.props.actions.getFeatures(zone_id))
      .then(() => {
        if (vehicleLocationCount > 0) {
          this.setState({
            mapPlaces: formattedPlaces,
            popupType: MAP,
            open: true,
            loaded: true,
          });
        } else {
          alert('Error: There was an error loading the Ride Map for this ride.');
          this.setState({
            loaded: true,
          });
        }
      });
  };

  loadPopup = () => {
    const type = this.state.popupType;
    switch (type) {
      case MAP:
        return (
          <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '600px' }} />}
            mapElement={<div style={{ height: '95%' }} />}
            center={this.state.mapCenter}
            zoom={16}
            places={this.state.mapPlaces}
            parkingSpots={this.props.features.parkingSpots}
            restrictedAreas={this.props.features.restrictedAreas.filter((ra) => ra.show_on_map)}
            showPolygons={true}
            mapZoneBoundary={this.state.mapZoneBoundary}
            showControls={true}
            showUserRide={true}
          />
        );
      case INVOICE:
        return (
          <div className="modal">
            <div className="closeModalBtn" onClick={this.closeModal}>
              <img
                src={closeButtonImage}
                alt="Close Modal Button"
                onClick={this.closePopup}
                className=""
                style={{
                  paddingTop: '3px',
                  alignSelf: 'center',
                }}
              />
            </div>
            <RideDetails ride={this.state.selectedRide} restrictRefund="true" />
          </div>
        );
      default:
        return null;
    }
  };

  closePopup = () => {
    this.setState({
      open: false,
    });
  };

  performIccidDelete = () => {
    const params = {};
    params.iccid = null;
    patchVehicle(this.state.vehicle.hash, params)
      .then(() => {
        this.props.actions.getVehicleByName(this.state.name);
        this.setState({
          feedback: 'Scooter updated successfully!',
          feedbackColor: 'green',
          loaded: true,
          hasDeletedIccid: true,
          iccid: null,
          hasChangedICCID: false,
        });
      })
      .catch((error) => {
        this.setState({
          feedback: error.message || 'An unknown error occurred',
          feedbackColor: 'red',
          disabled: false,
          loaded: true,
        });
      });
  };

  onClickDeleteIccid = (event) => {
    event.preventDefault();
    if (window.confirm('Are you sure you want to permanently delete this ICCID?')) {
      this.performIccidDelete();
    }
  };

  onClickReset = (event) => {
    event.preventDefault();
    this.setState({
      name: '',
      iccid: '',
      vehicleType: null,
      batteryType: null,
      commType: null,
      showVehicleRidesTable: false,
    });
    this.props.actions.clearVehicle();
    this.props.actions.clearVehicleRides();
  };

  render() {
    const title =
      this.state.vehicle && this.state.vehicle.status === 'written_off'
        ? 'Recover Scooter'
        : 'Write Off Scooter';
    const modalTitle = <ModalTitle title={title} />;
    const {
      vehicle,
      showVehicleRidesTable,
      selectedRecoveryReason,
      isWrittenoff,
      selectedWriteoffReason,
      batteryType,
      commType,
      name,
      iccid,
      vehicleType,
      vehicle_zone,
      vehicleStatus,
      hasLock,
    } = this.state;
    const { restrictedAccess } = this.props;
    const hasChangedICCID =
      vehicle &&
      iccid !== vehicle.iccid &&
      !this.state.hasDeletedIccid &&
      this.state.vehicle.iccid !== null;
    const hasChangedVehicleType = vehicle && Number(vehicleType) !== vehicle.vehicle_type;
    const hasChangedCommType = vehicle && Number(commType) !== vehicle.comm_type.id;
    const hasChangedBatteryType = vehicle && Number(batteryType) !== vehicle.battery_type;
    const hasChangedStatus = vehicle && vehicleStatus && vehicleStatus !== vehicle.status;
    const zone_name =
      vehicle_zone && vehicle_zone !== '' && vehicle_zone.length !== 0
        ? vehicle_zone[0].name
        : null;
    const settableStatuses = ['pickup_requested', 'reserved', 'damaged_scrapped', 'charging'];
    return (
      <AuthenticatedPage className="edit_vehicle_container">
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closePopup}
          overlayStyle={{ backgroundColor: 'light-grey' }}
          contentStyle={{ maxHeight: '100vh', overflowY: 'auto' }}>
          {this.loadPopup}
        </Popup>
        <div className="page-title">Vehicle Search 🔍</div>
        <form onSubmit={this.onSubmitVehicle}>
          <div className="form_input_section">
            <label className="form_input_section__label" htmlFor="name">
              Vehicle QR Code: 
            </label>
            <input
              type="text"
              name="name"
              placeholder="QR Code / Scan ID"
              value={name}
              readOnly={vehicle != null}
              onChange={this.onChange}
            />
          </div>
          {vehicle && !restrictedAccess && (
            <Fragment>
              <div className="form_input_section">
                <label className="form_input_section__label" htmlFor="vehicleHash">
                  Roamr ID (Hash)
                </label>
                <input type="text" name="vehicleHash" value={vehicle.hash} readOnly={true} />
              </div>
              <div className="form_input_section">
                <label className="form_input_section__label" htmlFor="iccid">
                  Connect ID / ICCID (32 char max)
                </label>
                <input
                  type="text"
                  name="iccid"
                  placeholder="iccid"
                  value={iccid}
                  onChange={this.onChange}
                  className={hasChangedICCID && 'changed'}
                />
                <button onClick={this.onClickDeleteIccid}>Erase ICCID</button>
              </div>
              <div className="form_input_section">
                <label className="form_input_section__label" htmlFor="vehicleType">
                  Vehicle Type
                </label>
                <select
                  name="vehicleType"
                  onChange={this.onChange}
                  value={vehicleType}
                  className={hasChangedVehicleType && 'changed'}>
                  {this.props.vehicleTypes.map(({ id, name: vehicleName }) => (
                    <option key={`vehicle_type_${id}`} value={id}>
                      {vehicleName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form_input_section">
                <label className="form_input_section__label" htmlFor="commType">
                  Comm Type
                </label>
                <select
                  name="commType"
                  onChange={this.onChange}
                  value={commType}
                  className={hasChangedCommType && 'changed'}>
                  {this.props.commTypes.map(({ id: commTypeId, protocol, provider }) => (
                    <option key={`comm_type_${commTypeId}`} value={commTypeId}>
                      {`${provider} - ${protocol}`}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form_input_section">
                <label className="form_input_section__label" htmlFor="batteryType">
                  Battery Type
                </label>
                <select
                  name="batteryType"
                  onChange={this.onChange}
                  value={batteryType}
                  className={hasChangedBatteryType && 'changed'}>
                  {this.props.batteryTypes.map(({ id: batteryId, name: batteryName }) => (
                    <option key={`battery_type_${batteryId}`} value={batteryId}>
                      {batteryName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form_input_section">
                <label className="form_input_section__label" htmlFor="batteryType">
                  Status
                </label>
                <select
                  name="vehicleStatus"
                  onChange={this.onChange}
                  value={vehicleStatus}
                  className={hasChangedStatus && 'changed'}>
                  {this.props.vehicleStatuses.map((status) => (
                    <option key={status} disabled={!settableStatuses.includes(status)}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form_input_section">
                <label className="form_input_section__label" htmlFor="hasLock">
                  Has Lock-To
                </label>
                <input
                  type="checkbox"
                  checked={hasLock}
                  onChange={(event) => this.setState({ hasLock: event.target.checked })}
                />
              </div>
            </Fragment>
          )}
          <div className="form_input_section">
            <input type="submit" disabled={this.state.disabled} value="Submit" />
            <button onClick={this.onClickReset}>Reset</button>
          </div>
        </form>
        {vehicle !== null && (
          <Fragment>
            <span className="write_off_link" onClick={this.showModal}>
              {isWrittenoff ? 'Recover ' : 'Write Off '} Scooter
            </span>
            <p style={{ color: this.state.feedbackColor }}>{this.state.feedback}</p>
          </Fragment>
        )}
        {showVehicleRidesTable && (
          <Vehicles
            vehicleRides={this.props.vehicleRides}
            onClickInvoice={this.openInvoicePopup}
            onClickRideMap={this.openMapPopup}
          />
        )}
        <Modal
          title={modalTitle}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <h3>Select Zone: {zone_name}</h3>
          {isWrittenoff ? (
            <Fragment>
              <h3>Recovery Reason:</h3>
              <DropdownList
                className="vehicles_container__select"
                data={this.recoveryReasons}
                value={selectedRecoveryReason}
                valueField="id"
                textField="name"
                onChange={this.handleRecoveryReasonChange}
                placeholder="Select a Zone"
              />
            </Fragment>
          ) : (
            <Fragment>
              <h3>Writeoff Reason:</h3>
              <DropdownList
                className="vehicles_container__select"
                data={this.writeoffReasons}
                value={selectedWriteoffReason}
                valueField="id"
                textField="name"
                onChange={this.handleWriteoffReasonChange}
                placeholder="Select a Zone"
              />
            </Fragment>
          )}
        </Modal>
        <Loader loaded={this.state.loaded} />
      </AuthenticatedPage>
    );
  }
}

EditScooter.propTypes = {
  actions: PropTypes.object,
  admin: PropTypes.object,
  batteryTypes: PropTypes.array,
  commTypes: PropTypes.array,
  features: PropTypes.object,
  location: PropTypes.object,
  restrictedAccess: PropTypes.bool,
  vehicle: PropTypes.object,
  vehicleRides: PropTypes.array,
  vehicleStatuses: PropTypes.array,
  vehicleTypes: PropTypes.array,
  zones: PropTypes.array,
  rides: PropTypes.object, // TODO: Why is this an object?
};

const mapStateToProps = (state) => {
  const {
    admin,
    battery_types,
    comm_types,
    rides,
    vehicle,
    vehicle_rides,
    vehicle_statuses,
    vehicle_types,
    zones: { features, zones },
  } = state;
  const restrictedAccess = admin && admin.restricted_access;
  return {
    admin,
    batteryTypes: battery_types,
    commTypes: comm_types,
    features,
    restrictedAccess,
    rides,
    vehicle,
    vehicleRides: vehicle_rides,
    vehicleStatuses: vehicle_statuses,
    vehicleTypes: vehicle_types,
    zones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        clearVehicle,
        clearVehicleRides,
        getBatteryTypes,
        getCommTypes,
        getFeatures,
        getRefundForRide,
        getRideById,
        getRideLocations,
        getVehicleTypes,
        getVehicleByName,
        getVehicleRides,
        getVehicleStatuses,
        writeoff,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditScooter);
